import store from '../store/index.js'

export default {
  async getPageBySlug(slug) {
    return axios.get(process.env.VUE_APP_ROOT_API + '/page/' + slug, {
      params: {
        locale: store.state.app.locale.code,
        channel: store.state.app.channel.code,
        currency: store.state.app.currency.code,
      }
    }
    ).then(response => {
      return response.data.data
    }).catch(error => {
      console.log(error);
    })
  },
}
