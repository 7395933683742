import store from '../store/index.js'
import Customer from "../constructors/Customer.js"

export default {
  customerLogin(email, password) {
      return axios.post(process.env.VUE_APP_ROOT_API + '/customer/login', {
          email: email,
          password: password
      }
      ).then(response => {
        // The logged in customer is returned
        // Instantiate customer
        // Mutate state

        var customer = new Customer(response.data.data);
        store.commit('mutateCustomer', customer);
        store.commit('mutateCustomerIsAuthenticated', true);
      }).catch(error => {
        console.log(error);
      })
  },
  getLoggedInCustomer() {
      return axios.get(process.env.VUE_APP_ROOT_API + '/customer/get', {
        //
      }).then(response => {
        // The logged in customer is returned
        // Instantiate customer
        // Mutate state
        var customer = new Customer(response.data.data);
        store.commit('mutateCustomer', customer);
        store.commit('mutateCustomerIsAuthenticated', true);
        return response.data;
      }).catch(error => {
        if (error.response) {
          if (error.response.status == '401') {
            // Unauthorized, mutate state
            store.commit('mutateCustomer', null);
            store.commit('mutateCustomerIsAuthenticated', false);
            return false;
          }
        }
      })
  },
  isAuthenticated() {
      return axios.get(process.env.VUE_APP_ROOT_API + '/customer/get', {

      }
    ).then(response => {
        // The logged in customer is returned
        // Instantiate customer
        var customer = new Customer(response.data.data);
        store.commit('mutateCustomer', customer);
        store.commit('mutateCustomerIsAuthenticated', true);
        return true;
      }).catch(error => {
        if (error.response) {
          if (error.response.status == '401') {
            // Unauthorized, mutate state
            store.commit('mutateCustomer', null);
            store.commit('mutateCustomerIsAuthenticated', false);
            return false;
          }
        }
      })
  },
  getLoggedInCustomerAddresses() {
      return axios.get(process.env.VUE_APP_ROOT_API + '/addresses', {

      }
      ).then(response => {
        // The logged in customer is returned
        // Instantiate customer
        // Mutate state
        var customer = store.state.customer;
        customer['addresses'] = response.data.data;
        store.commit('mutateCustomer', customer);
        return response.data;
      }).catch(error => {
        console.log(error);
      })
  },
  logCustomerOut() {
      return axios.get(process.env.VUE_APP_ROOT_API + '/customer/logout', {

      }
      ).then(response => {
        store.commit('mutateCustomer', null);
        store.commit('mutateCustomerIsAuthenticated', false);
        return response.data.data;
      }).catch(error => {
        console.log(error);
      })
  }

}
