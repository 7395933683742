<template>
  <transition name="fade-transition" >

          <v-app-bar

            :app="true"
            :flat="true"
            fixed
            color="background"
            :style="'border-bottom:1px solid lightgrey!important; color:' + this.$vuetify.theme.currentTheme.onBackground + ';'"
          >

          <v-row id="my-row" style="margin-left:12px; margin-right:12px;" >

            <v-col offset-xs="0" xs="2" offset-sm="2" sm="2" md="2" offset-md="2" lg="2" offset-lg="2" class="pl-0" >
              <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> -->
              <v-btn icon @click.stop="drawer = !drawer">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </v-col>


            <v-col xs="4" sm="4" lg="4" md="4">
              <v-toolbar-title
                v-if="$vuetify.breakpoint"
                align="center"
              >
                <span style="cursor:pointer;">
                  <router-link to="/" tag="span" align="center">
                  {{ApplicationTitle}}
                  </router-link>
                </span>
              </v-toolbar-title>
            </v-col>

            <v-col xs="2" sm="2" lg="2" align="end">

              <v-row>

                <v-col xs="2" sm="2" lg="9" align="" justify="center">
                  <img src="/img/flags/united-states.svg" alt="" style="cursor:pointer; width:24px; height:24px; margin-top: 12px;" justify="center" v-if="applicationLocale === 'en_US'" @click="regionDialog = true">
                  <img src="/img/flags/european-union.svg" alt="" style="cursor:pointer; width:24px; height:24px; margin-top: 12px;" justify="center" v-if="applicationLocale === 'en_EU'" @click="regionDialog = true">
                  <img src="/img/flags/united-kingdom.svg" alt="" style="cursor:pointer; width:24px; height:24px; margin-top: 12px;" justify="center" v-if="applicationLocale === 'en_GB'" @click="regionDialog = true">
                  <img src="/img/flags/denmark.svg" alt="" style="cursor:pointer; width:24px; height:24px; margin-top: 12px;" justify="center" v-if="applicationLocale === 'da_DK'" @click="regionDialog = true">
                </v-col>

                <v-col cols="1" class="pl-0 ml-0">

                  <v-btn  to="/cart" icon v-if="parseInt(cartItemCount) == 0 || cartItemCount === undefined">
                    <v-icon>mdi-cart</v-icon>
                  </v-btn>

                  <v-btn icon v-if="parseInt(cartItemCount)" to="/cart">

                  <v-badge
                    color="green"
                    :content="parseInt(cartItemCount)"
                  >
                    <v-icon to="/cart">mdi-cart</v-icon>
                  </v-badge>
                  </v-btn>

                </v-col>

                <v-col cols="1" class="pl-0 ml-0">
                  <!-- Works as a placeholder -->
                </v-col>

              </v-row>


            </v-col>

          </v-row>

          <v-row id="old-row" v-if="false">

          <v-spacer class="hidden-sm-and-down" />

            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

            <!-- <v-btn icon class="hidden-sm-and-up">
              <v-icon>mdi-magnify</v-icon>
            </v-btn> -->
            <v-spacer />

            <v-toolbar-title
              v-if="$vuetify.breakpoint"
              :align="$vuetify.breakpoint.smAndDown ? 'center' : 'start'"
            >
              <span style="cursor:pointer;">
                <router-link to="/" tag="span" align="center">
                {{ApplicationTitle}}
                </router-link>
              </span>
            </v-toolbar-title>

            <v-spacer class="hidden-sm-and-down" />
            <v-spacer class="hidden-sm-and-down" />
            <v-spacer class="hidden-sm-and-down" />
            <v-spacer class="hidden-sm-and-down" />

            <v-btn to="/cart" icon v-if="parseInt(cartItemCount) == 0 || cartItemCount === undefined">
              <v-icon>mdi-cart</v-icon>
            </v-btn>

            <v-btn icon v-if="parseInt(cartItemCount)" to="/cart">

            <v-badge
              color="green"
              :content="parseInt(cartItemCount)"
            >
              <v-icon to="/cart">mdi-cart</v-icon>
            </v-badge>
            </v-btn>

            </v-row>

          </v-app-bar>


  </transition>
</template>

<script>
export default {
  name: 'TopBar',
  data: () => ({

  }),
  mounted() {

  },
  computed: {
    cartItemCount: function () {
      if (this.$store.state.checkout.cart != null) {
        return parseInt(this.$store.state.checkout.cart.items_count);
      }
      return 0;
    },
    ApplicationTitle: function () {
      return this.$store.state.app.title;
    },
    drawer: {
      // getter
      get: function () {
        return this.$store.state.app.drawer
      },
      // setter
      set: function (newValue) {
        this.$store.commit('mutateDrawer', newValue);
      }
    },
    regionDialog: {
      // getter
      get: function () {
        return this.$store.state.app.regionDialog;
      },
      // setter
      set: function (newValue) {
        this.$store.commit('mutateRegionDialog', newValue);
      }
    },
    locale () {
      if (!this.$store.state.app.locale) {
        // Set default locale
        return {code: process.env.VUE_APP_I18N_LOCALE}
      }
      return this.$store.state.app.locale
    },
    applicationLocale: {
      // getter
      get: function () {
        if (this.$store.state.app.locale) {
          return this.$store.state.app.locale.code;
        }
        return {code: process.env.VUE_APP_I18N_LOCALE}
      },
      // setter
      set: function (newValue) {
        this.$store.commit('mutateLocale', newValue);
      }
     },

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>

.product {
  cursor: pointer;
}

#product-image-container {
  min-height:230px;
}

.product-image:hover {
    // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.product-title {
  font-family: 'Playfair Display', serif;
}

.product-subtitle {
  font-family: 'Roboto Mono', monospace;
}

.product:active {
  // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  -webkit-transform: scale(0.98, 0.98);
  transform: scale(0.98, 0.98);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}


</style>
