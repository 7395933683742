import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index.js'

Vue.use(VueRouter);

const routes = [
    {
      path: '/',
      name: 'home',
      meta: {
        keepAlive: false,
        components: {
          TextBlock: true
        },
        // title: 'Houstead Studio',
        // metaTags: [
        //   {
        //     name: 'description',
        //     content: 'Scandinavian - Nordic design posters at great prices'
        //   },
        //   {
        //     property: 'og:title',
        //     content: 'Houstead Premium Posters'
        //   },
        //   {
        //     property: 'og:description',
        //     content: 'Scandinavian - Nordic design posters at great prices'
        //   },
        //   {
        //     property: 'og:image',
        //     content: 'https://cdn.houstead.com/content/main-image-november-2020.jpg'
        //   }
        // ]
      },
      // route level code-splitting
      // this generates a separate chunk (home.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    },
    {
      path: '/page/:slug',
      name: 'page',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (page.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "page" */ '../views/Page.vue'),
    },
    {
      path: '/page/about',
      name: 'about',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
      path: '/product/:url_key',
      name: 'product-details',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: true
        }
      },
      // route level code-splitting
      // this generates a separate chunk (product-details.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "product-details" */ '../views/ProductDetails/index.vue'),
    },
    {
      path: '/collections',
      name: 'category-index',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: true
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "category-index" */ '../views/Categories.vue'),
    },
    {
      path: '/inspiration',
      name: 'inspiration-index',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: true
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "inspiration-index" */ '../views/Inspiration.vue'),
    },
    {
      path: '/collection/:category_slug',
      name: 'category',
      meta: {
        keepAlive: true,
        components: {
          TextBlock: true
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "category-index" */ '../views/CategoryIndex.vue'),
    },
    {
      path: '/cart',
      name: 'cart',
      meta: {
        keepAlive: false,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue'),
    },
    {
      path: '/order/confirmation',
      name: 'OrderConfirmation',
      meta: {
        keepAlive: false,
        requiresAuth: false,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "OrderConfirmation" */ '../views/checkout/OrderConfirmation.vue'),
    },
    {
      path: '/customer/login',
      name: 'login',
      meta: {
        keepAlive: false,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue'),
    },
    {
      path: '/customer/profile',
      name: 'profile',
      meta: {
        keepAlive: false,
        requiresAuth: true,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "profile" */ '../views/user/Profile.vue'),
    },
    {
      path: '/avatar/designer',
      name: 'avatarDesigner',
      meta: {
        keepAlive: true,
        requiresAuth: false,
        components: {
          TextBlock: false
        }
      },
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "avatar.designer" */ '@/packages/Avatar/src/resources/views/AvatarDesigner/index.vue'),
    }
];

const router = new VueRouter({
  mode: 'history',
  routes,
    scrollBehavior (to, from, savedPosition) {
    let position = { x: 0, y: 0 }
    // Keep scroll position when using browser buttons
    if (savedPosition) {
      position = savedPosition
    }

    // Workaround for transitions scrolling to the top of the page
    // However, there are still some problems being fixed by the vue team
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position)
      }, 200)
    })
  }
});


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

  if (to.matched.some(m => m.meta.requiresAuth) && !store.state.userAuthenticated) {

    if (to.matched.some(m => m.meta.admin)) {
      return next({ path: '/admin/login?redirect=' + to.fullPath })
    } else {
      return next({ path: '/customer/login?redirect=' + to.fullPath })
    }
  }

  return next();
});

export default router;
