import * as R from 'ramda'
// import { isValid, format } from "date-fns"
import { required, alpha } from "vuelidate/lib/validators"
// import ContentService from "services/content"
// import Wish from "constructors/Wish"

export default class Customer {
  constructor(customer = {}) {
    this.id         = R.is(Number, customer.id) ? customer.id : null
    this.firstName      = R.is(String, customer.first_name) ? customer.first_name : ""
    this.lastName      = R.is(String, customer.last_name) ? customer.last_name : ""
    this.name      = R.is(String, customer.name) ? customer.name : ""
    this.email      = R.is(String, customer.email) ? customer.email : ""
    this.phone      = R.is(String, customer.phone) ? customer.phone : ""
    this.addresses      = R.is(Array, customer.addresses) ? customer.addresses : []



    // We can even use some other constructors inside constructor,
    // for resources that have DB relations
    // this.driver     = R.is(Object, car.driver) ? new Driver(car.driver) : new Driver()

    // Validations of properites can be more complex
    // this.producedAt = car.producedAt && isValid(car.producedAt)
    //   ? format(car.producedAt, "DD-MM-YYYY")
    //   : null
  }

  // This logic could also be abstracted to some WishlistService
  // get title() {
  //   return this.title
  // }
  //
  // set title(title) {
  //   this.title = title
  // }


  toCreatePayload() {
    return {
      title: this.title,
      coverPhoto: this.coverPhoto
    }
  }

  // Call this function when you want to receive object ready to be send to update API endpoint
  toUpdatePayload() {
    return {
      ...this.toCreatePayload(),
      id: this.id
    }
  }

  // Static properties allow you to use some logic
  // without instantiating the constructor => Car.errors
  // static get errors() {
  //   return {
  //     // brand: {
  //     //   required: ContentService("errors.required", { attr: ContentService("attrs.brand") }),
  //     //   alpha: ContentService("errors.alpha", { attr: ContentService("attrs.brand") })
  //     // },
  //   }
  // }

  // Want to have your Vuelidate validations in one place? No problem!
  static get validations() {
    return {
      // brand: { required, alpha }
    }
  }
}
