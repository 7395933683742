import store from '../store/index.js'
import ListItem from "../constructors/ListItem.js"

export default {
  methods: {
    getSubCategories (parent_id) {
       return axios.get(process.env.VUE_APP_ROOT_API + '/descendant-categories', {
         params: {
           locale: store.state.app.locale.code,
           channel: store.state.app.channel.code,
           currency: store.state.app.currency.code,
           parent_id: parent_id
         }
       }
       ).then(response => {

         var listItems = [];
         for (var i = 0; i < response.data.data.length; i++) {
           var listItem = new ListItem(response.data.data[i]);
           listItems.push(listItem);
         }

         // console.log(customer);
         // store.state.customer = customer;
         return listItems;
       }).catch(error => {
         console.log(error);
       })
   },
   getCategories(parent_id) {
      return axios.get(process.env.VUE_APP_ROOT_API + '/descendant-categories', {
        params: {
          locale: store.state.app.locale.code,
          channel: store.state.app.channel.code,
          currency: store.state.app.currency.code,
          parent_id: parent_id
        }
      }
      ).then(response => {

        return response;
      }).catch(error => {
        console.log(error);
      })
  },
   getCategoryBySlug (category_slug) {
      return axios.get(process.env.VUE_APP_ROOT_API + '/getCategoryBySlug', {
        params: {
          // locale: store.state.app.locale.code,
          locale: 'da_DK',
          // channel: store.state.app.channel.code,
          // currency: store.state.app.currency.code,

          category_slug: category_slug
        }
      }
      ).then(response => {
        return response.data.data;
      }).catch(error => {
        console.log(error);
      })
  }
  }
}
