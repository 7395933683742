import Vue from 'vue';

import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import VueWorker from 'vue-worker';
// import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n.js';
import VueGtm from "vue-gtm";
import VueMeta from 'vue-meta';

Vue.use(VueMeta)


Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_CONTAINER_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  // queryParams: {
  //   // Add url query string when load gtm.js with GTM ID (optional)
  //   gtm_auth: "AB7cDEf3GHIjkl-MnOP8qr",
  //   gtm_preview: "env-4",
  //   gtm_cookies_win: "x",
  // },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.NODE_ENV === 'development' ? true : false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: [], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
});




Vue.use(VueWorker);

axios.defaults.withCredentials = true;

window.axios = axios;

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

// create Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});

if (store.state.authToken) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.state.authToken;
}


axiosInstance.interceptors.request.use(config => {
  config.params = {
      channel: process.env.VUE_APP_CHANNEL,
      locale: store.state.app.locale ? store.state.app.locale.code : process.env.VUE_APP_I18N_LOCALE,
      currency: store.state.app.currency ? store.state.app.currency.code : process.env.VUE_APP_DEFAULT_CURRENCY_CODE
    }

  return config;
});

window.axiosInstance = axiosInstance;

// Configure app
store.commit('mutateAppTitle', process.env.VUE_APP_TITLE)

window.eventBus = new Vue();

const app = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
  // mounted: () => setTimeout(function(){ document.dispatchEvent(new Event("x-app-rendered")); }, 3000000)
  mounted: () => setTimeout(function(){
    document.dispatchEvent(new Event("x-app-rendered"));
  }, 25000) // wait 25 sec. and take snapshot
}).$mount('#app');
