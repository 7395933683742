
export default {
    primary: {
      base: '#6f7461',
      lighten1: '#868c75'
    },
    onPrimary: '#FFFFFF',
    secondary: '#f8aca4',
    onSecondary: '#000',
    tertiary: '#f8aca4',
    onTertiary: '#fec145',
    surface: '#FFFFFF',
    onSurface: '#FFFFFF',
    accent: '#ff5722',
    background: {
      base: '#FFFFFF',
      lighten1: '#FFFFFF'
    },
    onBackground: '#000',
    error: '#f44336',
    onError: '#FFFFFF',
    warning: '#ffeb3b',
    onWarning: '#FFFFFF',
    info: '#03a9f4',
    onInfo: '#FFFFFF',
    success: '#8bc34a',
    onSuccess: '#8bc34a'
}


// Fonts
// Display: Nunito
// Body: Roboto monospace
